import React from 'react';
import PropTypes from 'prop-types';

import Link from 'molecules/Link';

import './MinorNewsSnippet.scss';

/**
 * Minor post snippet.
 */
const propTypes = {
  news: PropTypes.object
}
const MinorPostSnippet = ( { news } ) => {

  return(

    <div
    className="MinorSnippet"
    >

      <div className="MinorSnippet__content">

            <h2
            className="title h-sm"
            itemprop="title"
            >
              <Link
              className="d-inline-block"
              to={ news.url }
              rel="bookmark"
              >
                { news.title }
              </Link>
            </h2>
            <div
            className="author"
            itemprop="author"
            >

              { news.publisher }

           </div>


          <Link
          className="more f-more d-inline-block"
          to={ news.url }
          >
            Read More
          </Link>
        </div>

    </div>


  )

}
MinorPostSnippet.propTypes = propTypes;

export default MinorPostSnippet;
