

import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { MdArrowForward } from "react-icons/md";

import './FlyWheel.scss';

/**
 * Flywheel.
 */
const propTypes = {
  size: PropTypes.string,
}
const FlyWheel = p => {

  const className = classnames( 'FlyWheel', {
    [`is-${p.size}`]: !! p.size,
  } );

  return(

    <div className={ className }>
      <div className="inner">


          <div className="FlyWheel__icon first">
              <i className="epsilon-filing"></i>
          </div>
          <div className="FlyWheel__icon second">
              <i className="epsilon-graph-peak"></i>
          </div>
          <div className="FlyWheel__icon third">
              <i className="epsilon-random-graph"></i>
          </div>
          <div className="FlyWheel__icon fourth">
              <i className="epsilon-reinvest"></i>
          </div>


          <div className="FlyWheel__outer">
            <MdArrowForward
            className="FlyWheel__outer-arrow"
            />

          </div>


          <div className="FlyWheel__inner">
              <div className="text"></div>
          </div>

      </div>
    </div>


  )

};
FlyWheel.propTypes = propTypes;

export default FlyWheel;

