import classnames from 'classnames';
import React from 'react';
import to from 'await-to-js';
import { graphql } from 'gatsby';

import BlockContent from 'molecules/BlockContent';
import Button from 'molecules/Button';
import Container from 'molecules/Container';
import FlyWheel from 'molecules/FlyWheel';
import Image from 'molecules/Image';
import Link from 'molecules/Link';
import loadable from '@loadable/component';
import MinorNewsSnippet from 'organisms/MinorNewsSnippet';
import SEO from 'molecules/SEO';
import WithBGImage from 'molecules/WithBGImage';
import { client as sanity } from 'lib/sanity';

import { pageMetaFields } from 'lib/fragments';
import SwirlyDivider from 'static/images/divider-swirly.inline.svg';
import './index.scss';

const ModalVideo = loadable( () => import( 'molecules/ModalVideo' ) );

/**
 * Home template.
 */
export const query = graphql`
  query Query( $id: String ) {
    page: sanityPage( id: { eq: $id } ){
      id
      title
      slug {
        current
      }
      _rawPageTemplate(resolveReferences: { maxDepth: 10 })
      ...pageMetaFields
    }
    news: allSanityNews( limit: 3, sort: {fields: datePublished, order: DESC }) {
      nodes {
        id
        title
        url
        publisher
      }
    }
  }
`;
const Home = ( { data,  path } ) => {

  const {
    news,
    page: {
      title,
      meta,
      _rawPageTemplate: {
        templateHome: {
          hero,
    //      flywheel,
          // objectives,
          overview,
          //recentPosts,
        }
      }
    }
  } = data;


  /* ------ = Lifecycle. = --------------------------------------------------------------------- */
  const [ isShowingVideo, setIsShowingVideo ] = React.useState( false );
  const [ newsItems, setNewsItems ] = React.useState( news.nodes );
  const [ noMoreNews, setNoMoreNews ] = React.useState( false );

  /**
   * Load more posts
   */
  const loadMorePosts = async() => {

    const client = require( 'lib/sanity' ).client;
    const count = newsItems.length;

    const query = `*[ _type == "news" ] | order( _createdAt desc )[ ${count}...${count + 6}]{ 
      id,
      title,
      url,
      publisher
    }`;

    const [ err, result ] = await to( sanity.fetch( query ) );
    if( err ) {
      console.warn( err );
    }

    if( ! result.length ) {
      return setNoMoreNews( true );
    }

    setNewsItems( [ ...newsItems, ...result ] );


  }

  /* ------ = Output. = --------------------------------------------------------------------- */

  return(

    <div className="Home">

      <SEO
      title={ title }
      path={ path }
      { ...meta }
      />

      { /* Hero */ }
      { !! hero &&
        <section className="Home__hero">
          <Container size="sm">
            { !! hero.preheadline &&
              <span className="Home__hero-prehline h-serif-i-sm">
                { hero.preheadline }
              </span>
            }
            <h1 className="Home__hero-hding h-lg">
              { hero.headline }
            </h1>
            <div className="Home__hero-vid">
              <button
              className="play"
              onClick={ () => setIsShowingVideo( true ) }
              >
                <i>▸</i>
                <span>Play Video</span>
              </button>
              <WithBGImage
              image={ {
                data: hero.videoPlaceholder.asset
              } }
              overlay={ {
                background: 'var( --blue-darker )',
                opacity: '0.4'
              } }
              />
            </div>
          </Container>

          { typeof window !== 'undefined' &&

            <ModalVideo
            channel="wistia"
            isOpen={ isShowingVideo }
            url={ hero.videoID }
            onClose={ () => setIsShowingVideo( false ) }
            />

        }

        </section>

      }

      { /* Overview */ }
      { !! overview &&

        <section className="Home__oview">
          <div className="Home__oview-divider">
            <SwirlyDivider />
          </div>

          <WithBGImage
          image={ {
            data: !! overview.bg ? overview.bg.asset : null
          } }
          overlay={ {
            background: 'var( --blue-darker )',
            opacity: '0.85'
          } }
          >
            <Container size="sm">

              <div className="Home__oview-wrap">
                {/* <h2 className="Home__oview-hding h-lg">
                  { overview.headline }
                </h2> */}
                <div className="Home__oview-content p-xl"
                style={ {
                  paddingTop: '2rem',
                  paddingBottom: '2rem',
                }}
                >
                  {/* <BlockContent
                  blocks={ overview.content }
                  /> */}
                  <div>Epsilon Asset Management was an investment adviser, formed in 2017. It’s principals, wound down the firm in 2023 but are keeping its research and writings available through this site. In addition, we have uploaded our historical quarterly letters for posterity.</div>

                  {/* <Link
                  className="Home__oview-cta d-inline-block"
                  to={ `/${overview.link.page.slug.current}` }
                  type={ overview.link.page._type }
                  >
                    { overview.link.text }
                  </Link> */}
                </div>
              </div>

            </Container>
          </WithBGImage>
        </section>


      }

      { /* Objectives */ }
      {/* { !! objectives &&

        <section className="Home__objtives">

          <Container className="Home__objtives-hline" size="sm">
            <h2 className="main h-serif-i-xl">{ objectives.headline }</h2>
            <p className="sub p-xl">{ objectives.summary }</p>
          </Container>

          { !! objectives.sections.length && objectives.sections.map( ( item, i ) => (

            <div
            key={ item._key }
            className="Home__objtives-section">
              <div className="row">

                <div className={ classnames( 'content', 'col-md-6', {
                  'order-md-12': i % 2 !== 0
                } ) }>
                  <span className="content__prehline d-block h-serif-i-sm">
                    { item.preheadline }
                  </span>
                  <h3 className="content__hline h-serif-md">
                    { item.headline }
                  </h3>
                  <p className="content__blurb p-xl">
                    { item.blurb }
                  </p>
                  <Link
                  className="content__cta"
                  to={ `/${ !!item.link.page && item.link.page.slug ? item.link.page.slug.current : '' }` }
                  type={ !! item.link.page ? item.link.page._type : null }
                  >
                    { item.link.text }
                  </Link>

                </div>

                <div
                className="img d-none d-md-flex col-md-6"
                aria-hidden="true"
                >
                  <WithBGImage
                  className="img__wrap"
                  image={ { data: !! item.image ?  item.image.asset : null } }
                  />
                </div>

              </div>
            </div>

          ) ) }


        </section>

      } */}

      { /* Flywheel */ }
      {/* { !! flywheel &&

        <section className="Home__fly">
          <Container>
            <div className="row">

              <div className="Home__fly-content col-md-6">
                <h2 className="h-serif-lg">{ flywheel.headline }</h2>
                <div className="summary p-lg">
                  <BlockContent blocks={ flywheel.summary } />
                  <Button
                  color="transparent"
                  link={ {
                    to: `/${flywheel.link.page.slug.current}`
                  } }
                  >
                    { flywheel.link.text }
                  </Button>
                </div>
              </div>

              <div className="Home__fly-graphic col-md-6">

                <FlyWheel
                size="sm"
                />

              </div>


            </div>
          </Container>

        </section>

      } */}

      { /* Recent Posts */ }
      {/* { !! newsItems && !! newsItems.length  &&

        <section className="Home__posts">

          <Container>

              <h2 className="h-serif-lg">{ recentPosts.headline}</h2>

              <div className="row">
                { newsItems.map( post => (

                  <div
                  key={ post.id }
                  className="col-md-4"
                  >
                    <MinorNewsSnippet news={ post } />
                  </div>

                ) ) }
              </div>

          </Container>
                
          { ! noMoreNews &&
            <button
            class="Home__posts-loadmore"
            onClick={ loadMorePosts }
            >
              <span>Show more news</span>
              <i>&#8675;</i>
            </button>
          }

        </section>


      } */}

    </div>

  )

};

export default Home;
